import React from "react";
import firebase from '../../firebase/firebaseIndex';
import {Redirect} from "react-router-dom";

const signOut = () => {
  firebase.auth().signOut();
  return (
    <Redirect to="/" />
  )
}

export default signOut;
