import React from 'react'; 
import CSSclasses from './Input.module.css'

//236. Creating a Custom Dynamic Input Component (inputElement wrapper)
const input = (props) => {
  let inputElement = null;

  //244. Adding Validation Feedback
  const inputClasses = [CSSclasses.InputElement];
  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push(CSSclasses.Invalid);
  }

  switch (props.elementType) {
    case('input'): 
      inputElement = <input className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed}/>;
      break; 
    case ('textarea'): 
      inputElement = <textarea className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed}/>;
      break; 
    case ('select'): //239. Adding a dropdown component. 
      inputElement = (<select className={inputClasses.join(' ')} value={props.value} onChange={props.changed}>
          {props.elementConfig.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break; 
    case ('radio'):
      inputElement = (
        <div className={inputClasses.join(' ')} value={props.value} onChange={props.changed}>
          {props.elementConfig.options.map(option => (
            <label key={option.value}><input type={props.elementConfig.type} key={option.value} name={option.name} value={option.value}/>
              {option.displayValue}
            </label> 
          ))}
        </div>
      );
      break;
    default: 
      inputElement = <input className={inputClasses.join(' ')} {...props.elementConfig} value={props.value} onChange={props.changed}/>;
  }

  return (
    <div className={CSSclasses.Input}>
      <label className={CSSclasses.Label}>{props.label}</label>
      {inputElement}
    </div>
  );
}

export default input; 
