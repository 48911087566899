import React, { Component } from "react";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";

import CSSclasses from "./Auth.module.css"
import { updateObject, checkValidity } from "../../shared/utility";
import firebase from '../../firebase/firebaseIndex';
import { googleAuthProvider } from '../../firebase/firebaseIndex';
import googleSignInPic from '../../assets/images/btn_google_signin_dark_normal_web.png'

class Auth extends Component {
  state = {
    title: 'Portfolios Viewer',
    controls: {
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Email Address',
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Password',
        },
        value: '',
        validation: {
          required: true,
          minLength: 6
        },
        valid: false,
        touched: false
      }
    },
    errorMessage: null, //display error message from await request
    resetPassword: false, //for displaying reset password message.
    loading: false, //load spinner while waiting for await request
    enableLogin: false, //check whether the inputs are valid for login submission
    enableResetPassword: false //check whether a valid email address is presented for submitting reset password request
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const updatedFormElement = updateObject(this.state.controls[inputIdentifier], {
      value: event.target.value,
      touched: true,
      valid: checkValidity(event.target.value, this.state.controls[inputIdentifier].validation)
    });
    const updatedControls = updateObject(this.state.controls, {
      [inputIdentifier]: updatedFormElement
    })
 
    let allowLogin = true; //both email and password need to be valid
    let allowResetPassword = false; //only email needs to be valid
    for (let input in updatedControls) {
      // console.log(input, updatedControls[input].valid)
      allowLogin = updatedControls[input].valid && allowLogin
      if (updatedControls["email"].valid) {
        allowResetPassword = true;
      }
    }
    this.setState({controls: updatedControls, enableLogin: allowLogin, enableResetPassword: allowResetPassword});
  }

  submitRegisterHandler = async(event) => {
    event.preventDefault();
    // this.setState({loading: true, errorMessage: null, resetPassword: false});
    this.setState({loading: true, resetPassword: false});
    try {
      await firebase.auth().createUserWithEmailAndPassword(this.state.controls.email.value, this.state.controls.password.value) 
    }
    catch(error) {
      this.setState({errorMessage: error.message});
    }
    this.setState({loading: false});
  }

  submitLoginHandler = async(event) => {
    event.preventDefault();
    this.setState({loading: true, resetPassword: false});
    try {
      await firebase.auth().signInWithEmailAndPassword(this.state.controls.email.value, this.state.controls.password.value) 
    }
    catch(error) {
      this.setState({errorMessage: error.message});
    }
    this.setState({loading: false});
  }

  submitResetHandler = async(event) => {
    event.preventDefault();
    this.setState({loading: true, resetPassword: true});
    try {
      await firebase.auth().sendPasswordResetEmail(this.state.controls.email.value) 
    }
    catch(error) {
      this.setState({errorMessage: error.message});
    }
    this.setState({loading: false});
  }

  submitSocialLogin = async(authProvider) => {
    var provider = null;
    if (authProvider === "Google") { provider = googleAuthProvider}

    this.setState({loading: true, resetPassword: false});
    try {
      await firebase.auth().signInWithPopup(provider)
    }
    catch(error) {
      this.setState({errorMessage: error.message});
    }
    this.setState({loading: false});
  }

  render() {
    const formElementsArray = []; 
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key, 
        config: this.state.controls[key]
      });
    }
    let form = formElementsArray.map(formElement => (
      <Input key={formElement.id}
        elementType={formElement.config.elementType} 
        elementConfig={formElement.config.elementConfig} 
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangedHandler(event, formElement.id)}
      ></Input>
    ))

    let displayMessage = null
    if (this.state.loading) {
      form = <Spinner />
    } else {
      if (this.state.errorMessage) {
        displayMessage = <p style={{fontWeight: "bold", color: "red"}}>{this.state.errorMessage}</p> 
      }
      if (this.state.resetPassword) {
        displayMessage = <p style={{fontWeight: "bold", color: "blue"}}>Please check {this.state.controls.email.value} for the password reset link.</p>
      }
    }

    return(
      <div className={CSSclasses.Auth}>
        <h1 style={{textAlign:'center', color:'#944317'}}>{this.state.title}</h1>
        {/* <div className={CSSclasses.Logo}><Logo /></div> */}
        {displayMessage}
        <form>
          {form}
          <Button btnType="Success" disabled={!this.state.enableLogin} clicked={this.submitLoginHandler}>LOGIN</Button>
          <Button btnType="Success" disabled={!this.state.enableLogin} clicked={this.submitRegisterHandler}>REGISTER</Button>
        </form>
        <form>
          <Button btnType="Danger" disabled={!this.state.enableResetPassword} clicked={this.submitResetHandler}>RESET PASSWORD</Button>
        </form>
          {/* <img src={googleSignInPic} alt="googleSignIn" onClick={() => this.submitSocialLogin("Google")}/> */}
          <button style={{border: '0px solid', backgroundColor: 'transparent', cursor: 'pointer'}} 
            onClick={() => this.submitSocialLogin("Google")}>
              <img src={googleSignInPic} alt="googleSignIn" />
          </button>
      </div>
    );
  }
}

export default Auth;
