import React from 'react';
import CSSclasses from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const toolbar = (props) => (
  <header className={CSSclasses.Toolbar}>
    <DrawerToggle clicked={props.drawToggleClicked}/>
    {/* this allow us to manage the logo css property from where it is loaded, in this case toolbar */}
    <div className={CSSclasses.Logo}><Logo /></div>
    {/* only display nav items on side drawer. */}
    <nav className={CSSclasses.DesktopOnly}>
      <NavigationItems isAuthenticated={props.isAuth}/>
    </nav>
  </header>
);

export default toolbar;
