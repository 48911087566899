import React from 'react';
import NavigationItem from './NavigationItem/NavigationItem';
import CSSclasses from './NavigationItems.module.css';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

const navigationItems = (props) => (
  <ul className={CSSclasses.NavigationItems}>
    {/* <NavigationItem link="/" exact>Public</NavigationItem> */}
    { props.isAuthenticated 
      ? <Aux>
          <NavigationItem link="/addstock">AddStock</NavigationItem>
          <NavigationItem link="/stocksummary">StockSummary</NavigationItem>
          <NavigationItem link="/profile">Profile</NavigationItem>
          <NavigationItem link="/signout">SignOut</NavigationItem>
        </Aux>
      : null
    }
    {/* { props.isAuthenticated 
      ? <NavigationItem link="/logout">Logout</NavigationItem>
      : <NavigationItem link="/auth">Authenticate</NavigationItem> 
    } */}
  </ul>
);

export default navigationItems;
