import React from 'react';
import displayLogo from '../../assets/images/Portfolios-Viewer.png'
import CSSclasses from './Logo.module.css'

const logo = (props) => (
  //overwrite the css height using props. 
  <div className={CSSclasses.Logo} style={{height: props.height}}>
    <img src={displayLogo} alt="displayLogo"></img>
  </div>
)

export default logo;
