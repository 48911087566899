import React, { Component } from "react";
import CSSclasses from './Forms.module.css';
import CSSclassesInput from '../../components/UI/Input/Input.module.css'
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';

import firebase from '../../firebase/firebaseIndex';
import MD5 from "crypto-js/md5";

class StockInput extends Component { 
  componentDidMount() {
    this.setState({});
  }

  state = {
    searchTicker: null,
    searchResult: null,
    stockCurrency: null,
    errorMessage: null,

    sharesPurchased: null,
    amountPaid: null,
    purchasedDate: null,

    loading: false, //load spinner while waiting for await request
  }
   
  callSearchTickerHandler = async(event) => {
    event.preventDefault();
    this.setState({loading: true})
    // firebase.functions().useEmulator("localhost", 5001)
    const REGION = "asia-southeast1";
    const getCurrentPrice = firebase.functions(REGION).httpsCallable('searchTickerHandler');
    // const response = await (await getCurrentPrice(this.state.searchTicker)).data.message;
    // const responseData = response.responseData
    const response = await getCurrentPrice(this.state.searchTicker)
    const responseData = await response.data.message.responseData
    console.log(response)
    //if return HTTP response status is 200
    if (response.data.code === 200) {
      this.setState({searchResult: responseData, stockCurrency: responseData.price.currency, errorMessage: null})
    } else {
      this.setState({errorMessage: `Ticker Symbol ${this.state.searchTicker} does not exists!`})
    }
    this.setState({loading: false})
  }

  addStockTransaction = async(event) => {
    event.preventDefault();
    this.setState({loading: true})
    let transactionData = {
      "ticker": this.state.searchTicker,
      "shares": this.state.sharesPurchased,
      "amount": this.state.amountPaid,
      "currency": this.state.stockCurrency,
      "date": this.state.purchasedDate,
    }
    const transactionHash = MD5(JSON.stringify(transactionData))


    try {
      await firebase.database().ref('stockInputs/'+firebase.auth().currentUser.uid+"/"+transactionHash).set(transactionData)
      alert("Added Successfully")
      this.props.history.push('/stocksummary')
    } catch(error) {
      this.setState({errorMessage: error})
    }
    this.setState({loading: false})
  }

  validateDigits = (value) => {
    let isValid = true;
    const pattern = /^(\d*\.)?\d+$/;
    isValid = pattern.test(value) && isValid;
    return isValid
  }

  validateDate = (value) => {
    let isValid = false;
    const currentDate = new Date(); 
    let inputDate = null;
    if (value != null) {
      inputDate = new Date(value);
      // console.log("INPUT:", inputDate.toDateString(), "CURRENT", currentDate.toDateString())
      if (currentDate > inputDate) {
        isValid = true;
      }
    }
    return isValid;
  }

  render() {  
    let displayError = null;
    if (this.state.errorMessage) {
      displayError = <p style={{fontWeight: "bold", color: "red"}}>{this.state.errorMessage}</p>
    }
    let disableSearch = true;
    if (this.state.searchTicker){
      disableSearch = false;
    }
    
    let searchTicker = (
      <form>
        <input className={CSSclassesInput.Input} style={{width: '50%', margin: '0 10px'}} type="text" placeholder='e.g. aapl for Apple' onChange={(event) => {this.setState({searchTicker: (event.target.value).toUpperCase(), searchResult: null})}} />
        <Button btnType="Success" disabled={disableSearch} clicked={this.callSearchTickerHandler}>SEARCH</Button>
      </form>
    );

    let addStock = null;
    //only when all fields are validated, then the add button will be enabled. 
    let enableAdd = true && this.validateDigits(this.state.sharesPurchased) && this.validateDigits(this.state.amountPaid) && this.validateDate(this.state.purchasedDate);
    if (this.state.searchResult){
      const amountPlaceHolder = `Total Amount in ${this.state.searchResult.price.currency} include transaction fee!`
      addStock = (
        <form>
          <label className={CSSclassesInput.Label}>{this.state.searchResult.quoteType.longName}</label>
          <input className={CSSclassesInput.Input} type="number" placeholder='Num of shares purchased, e.g. 1.5' onChange={(event) => {this.setState({sharesPurchased: event.target.value})}} />
          <input className={CSSclassesInput.Input} type="number" placeholder={amountPlaceHolder} onChange={(event) => {this.setState({amountPaid: event.target.value})}} />
          <input className={CSSclassesInput.Input} type="date" id="start" name="trip-start" onChange={(event) => {this.setState({purchasedDate: event.target.value})}} />
          <Button btnType="Success" disabled={!enableAdd} clicked={this.addStockTransaction}>ADD</Button>
        </form>
      )
    }

    return (
      <div className={CSSclasses.Form}>
        <h2>Stock Inputs</h2>
        {displayError}
        {searchTicker}
        {addStock}
        {this.state.loading ? <Spinner /> :null}
      </div>
    )
  }
}  

export default StockInput
