import React from 'react';
import Logo from '../../Logo/Logo'
import NavigationItems from '../NavigationItems/NavigationItems'
import CSSclasses from './SideDrawer.module.css'

import Aux from '../../../hoc/Auxiliary/Auxiliary'
import Backdrop from '../../UI/Backdrop/Backdrop'

const sideDrawer = (props) => {
  let attachedCSSclasses = [CSSclasses.SideDrawer, CSSclasses.Close].join(' ');
  if (props.open) {
    attachedCSSclasses = [CSSclasses.SideDrawer, CSSclasses.Open].join(' ');
  }

  return(
    <Aux>
      <Backdrop show={props.open} clicked={props.closed}/>
      <div className={attachedCSSclasses} onClick={props.closed}>
        <div className={CSSclasses.Logo}><Logo /></div>
        <nav>
          <NavigationItems isAuthenticated={props.isAuth}/>
        </nav>
      </div>
    </Aux>
  )
}

export default sideDrawer;
