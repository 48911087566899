export const updateObject = (oldObject, updatedObject) => {
  return {
    ...oldObject, 
    ...updatedObject
  }
}

//242. Adding Custom Form Validation. + 243. Fixing a Common Validation Gotcha + 346. Sharing the Validation Method
export const checkValidity = (value, rules) => {
  let isValid = true; 
  if (rules.required){ // validation { required: true}
    isValid = value.trim() !== '' && isValid; //if value is not empty (space is trimmed), then return true. 
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
}
