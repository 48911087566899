import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/app-check'


const firebaseConfig = {
  apiKey: "AIzaSyBCFZQO2JtCvHDfJlBb-2ojoYVsULjbkd4",
  authDomain: "portfolios-viewer.firebaseapp.com",
  databaseURL: "https://portfolios-viewer-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "portfolios-viewer",
  storageBucket: "portfolios-viewer.appspot.com",
  messagingSenderId: "301862387672",
  appId: "1:301862387672:web:3d19129e51d564ce78bcad",
  measurementId: "G-MYZ4VBP0TC"
};

const app = firebase.initializeApp(firebaseConfig);
const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate('6LeHmLkbAAAAACkbnc1h6PjNpgyg5THhfOeJKvFJ');

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export default app

