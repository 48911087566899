import React, { Component } from "react";
import firebase from '../../firebase/firebaseIndex'

class Profile extends Component { 
  render() {
    const displayProfile = (
      <div>
        <h1>Welcome {firebase.auth().currentUser.displayName}</h1>
        <p><strong>Email:</strong> {firebase.auth().currentUser.email}</p>
        {firebase.auth().currentUser.phone 
          ? <p><strong>Phone:</strong> {firebase.auth().currentUser.phone}</p> 
          : <p><strong>Phone:</strong> null </p>}
        <p><strong>Uid:</strong> {firebase.auth().currentUser.uid}</p>
      </div>
    )

    return (
      <div>
        {displayProfile} 
      </div>
    )
  }
}

export default Profile;
