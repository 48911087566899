import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import './App.css';
import Auth from '../src/containers/Auth/Auth';
import VerifyEmail from '../src/containers/Auth/VerifyEmail/VerifyEmail'
import Landing from '../src/containers/Landing/Landing';
import Layout from '../src/hoc/Layout/Layout';
// import firebaseConfig from './firebase/firebaseIndex';
// import firebase from "firebase/app";
import firebase from './firebase/firebaseIndex'

class App extends Component {
  state = {
    isAuth: false,
    userInfo: null,
    isEmailVerified: false
  }

  componentDidMount() {
    // Check whether user session is authenticated?
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //authenticated session found
        this.setState({isAuth: true})
        this.setState({userInfo: user})
        this.setState({isEmailVerified: user.emailVerified})
      } else {
        this.setState({isAuth: false})
        this.setState({userInfo: null})
      }
    });
  }

  render() {
    let route = null;
    if (this.state.isAuth && !this.state.isEmailVerified){
      route = <VerifyEmail />
    } else if (this.state.isAuth && this.state.isEmailVerified) {
      route = <Landing />
    } else {
      route = <Auth />
    }

    return (
      <div className="App">
        {/* only when a user is authenticated and email verified, then the protected resource tab can be displayed. */}
        <Layout isAuthenticated={(this.state.isAuth && this.state.isEmailVerified)}> 
          {route}
        </Layout>
      </div>
    );
  }
}

export default withRouter(App);
