import React, {Component} from 'react';
// import {connect} from "react-redux";
import Aux from '../Auxiliary/Auxiliary'
import CSSclasses from './Layout.module.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar'
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer'

class Layout extends Component{
  state = {
    showSideDrawer: false,
  }

  sideDrawerClosedHandler = () => {
    this.setState({showSideDrawer: false});
  }

  sideDrawerToggleHandler = () => {
    //AVOID this.setState({showSideDrawer: !this.state.showSideDrawer})
    //Using prevState is a cleaner way to setState when it depend on old state.  
    this.setState((prevState) => {
      return {showSideDrawer: !prevState.showSideDrawer};
    });  
  }

  render() {
    return(
      <Aux>
      <Toolbar 
        isAuth={this.props.isAuthenticated}
        drawToggleClicked={this.sideDrawerToggleHandler}/>
      <SideDrawer 
        isAuth={this.props.isAuthenticated}
        open={this.state.showSideDrawer} 
        closed={this.sideDrawerClosedHandler}/>
        <main className={CSSclasses.Content}>
          {this.props.children}
        </main>
      </Aux>
    )
  }
};

export default Layout;
