import React from 'react';
import CSSclasses from './Button.module.css'

const button = (props) => (
  <button disabled={props.disabled} 
    onClick={props.clicked} 
    className={[CSSclasses.Button, CSSclasses[props.btnType]].join(' ')}>
    {/* The button style can be dynamically changed based on props input. */}
    {props.children}
  </button>
)

export default button;
