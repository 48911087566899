import React, { Component } from "react";
import firebase from '../../firebase/firebaseIndex';

class StockSummary extends Component {
  componentDidMount() {
    this.gatherUserStocks(firebase.auth().currentUser.uid)
  }

  state = {
    allStockTransactions: null, 
    uniqueTickers: null,
    allStockCurrentPrice: null,
    errorMessage: null,
  }

  callGetAllStockCurrentPrice = async() => {
    // firebase.functions().useEmulator("localhost", 5001)
    const REGION = "asia-southeast1";
    let getCurrentPrice = firebase.functions(REGION).httpsCallable('getCurrentStockPrice');
    let result = await getCurrentPrice(this.state.uniqueTickers);
    // console.log(result.data.message)
    if (result !== null) {
      console.log("getAllStockCurrentPrice")
      this.setState({getAllStockCurrentPrice: result.data.message})
      console.log(this.state.getAllStockCurrentPrice)
    }
  }

  gatherUserStocks = async(userId) => {
    // firebase.functions().httpsCallable('getData')("ticker").then(result => console.log(result));
    try {
      const responseData = await (await firebase.database().ref().child("stockInputs").child(userId).get()).val()
      this.setState({allStockTransactions: responseData})
      let collectTicker = []
      for (let key in responseData) {
        collectTicker.push(responseData[key].ticker)
      }
      let uniqueTickers = [...new Set(collectTicker)]
      this.setState({uniqueTickers: uniqueTickers})
      this.callGetAllStockCurrentPrice()
      this.test()
      console.log(this.state.allStockCurrentPrice)
    } catch(error){
      this.setState({errorMessage: error})
    }
  }

  // getCurrentStockPrice = async(ticker) => {
  //   try {
  //     const url = "http://localhost:5000/currentprice?ticker="+ticker
  //     const responseData = await(await(fetch(url, {
  //       "method": "GET"
  //     }))).text()
  //     console.log(responseData)
  //   }catch (error){
  //     console.log(error)
  //   }
  // }

  calculateStockPerformance = () => {
    let summary = [];
    if (this.state.uniqueTickers) {
      this.state.uniqueTickers.forEach(ticker => {
        // this.getCurrentStockPrice(ticker)
        var totalAmount = 0;
        var totalShares = 0; 
        var averageCost = 0;
        var currency = null;
        const allStocks = this.state.allStockTransactions 
        for (let key in allStocks) {
          // console.log(allStocks[key])
          if (allStocks[key].ticker === ticker) {
            // console.log(allStocks[key].ticker, allStocks[key].amount, allStocks[key].shares, allStocks[key].currency)
            totalAmount += parseInt(allStocks[key].amount)
            totalShares += parseInt(allStocks[key].shares)
            currency = allStocks[key].currency
          }
        }
        averageCost = totalAmount/totalShares
        let eachPerformance = {
          "ticker": ticker,
          "currency": currency,
          "totalAmount": totalAmount,
          "totalShares": totalShares,
          "averageCost": averageCost
        }
        summary.push(eachPerformance)
      });
    }
    return summary
  }
  
  render() {
    let stockSummary = this.calculateStockPerformance()
    let allTransactions = this.state.allStockTransactions
    let element = []
    for (const stock of stockSummary) {
      element.push(
        <p key={Math.random().toString(36)}><strong>{stock.ticker}</strong>: {stock.currency} ${stock.totalAmount}, {stock.totalShares} shares, with an average ${stock.averageCost} per share.</p>
      )
      for (let key in allTransactions) {
        const trans = allTransactions[key]
        if (stock.ticker === trans.ticker) {
          element.push(
            <li key={Math.random().toString(36)}>On {trans.date} brought {trans.shares} shares at {trans.currency} ${trans.amount}</li>
          )
        }
      }
    }

    return(
      <div>
        {element}
      </div>
    );
  }
} 

export default StockSummary
