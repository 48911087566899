import React, {Component} from 'react';
import Button from '../../../components/UI/Button/Button' 
import firebase from '../../../firebase/firebaseIndex'
import CSSclasses from '../Auth.module.css'

class VerifyEmail extends Component{
  state = {
    errorMessage: null
  }

  sendEmailVerify = async(event) => {
    try {
      await firebase.auth().currentUser.sendEmailVerification()
    } catch(error) {
      this.setState({errorMessage: error.message});
    }
    firebase.auth().signOut();
  };

  render(){
    let displayMessage = null;
    if (this.state.errorMessage) {
      displayMessage = <p style={{fontWeight: "bold", color: "red"}}>{this.state.errorMessage}</p>
    } else {
      displayMessage = (
        <div>
          <p>Please verify your registered email address before proceeding.</p>
          <Button btnType="Success"clicked={this.sendEmailVerify}>
            Resend email for verification?</Button>
        </div>
      )
    }

    return (
      <div className={CSSclasses.Auth}>
        {displayMessage}
      </div>
    )
  }
}

export default VerifyEmail;
