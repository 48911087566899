import React from 'react';
import CSSclasses from './NavigationItem.module.css';
import {NavLink} from 'react-router-dom';

const navigationItem = (props) => (
  <li className={CSSclasses.NavigationItem}>
    {/* the className syntax allow us to dynamically manage the css style based on user navigation pattern.*/}
    <NavLink to={props.link}
      exact={props.exact}
      activeClassName={CSSclasses.active}>
      {props.children}
    </NavLink>
  </li>
);

export default navigationItem;

