import React, { Component } from "react";
import SignOut from '../Auth/SignOut';
import Profile from '../Profile/Profile';
import StockInput from '../Forms/StockInput'
import StockSummary from "../Forms/StockSummary";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

class Landing extends Component { 
  render() {
    let routes = ( 
      <Switch>     
        <Route path="/signout" component={SignOut} /> 
        <Route path="/profile" exact component={Profile} />
        <Route path="/addstock" exact component={StockInput} />
        <Route path="/stocksummary" exact component={StockSummary} />
        <Redirect to="/stocksummary" />
      </Switch>
    );

    return (
      <div className="App">
        {routes}
      </div>

    )
  }
}

export default withRouter(Landing);
